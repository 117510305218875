import { useState } from "react";
import { Scroll, ScrollControls } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import React from "react";
import * as THREE from "three";
import { Html } from "../Html";
import { Objects } from "../Objects";
import { Particles } from "../Particles";

export const Scene = () => {
  const [width] = useState(window.innerWidth);

  return (
    <>
      <ScrollControls
        pages={width <= 768 ? 5 : 4}
        damping={0.1}
        maxSpeed={1}
        eps={0.001}
      >
        <Scroll>
          <Particles />
          <Objects />
        </Scroll>
        <Scroll html>
          <Html />
        </Scroll>
      </ScrollControls>
    </>
  );
};
